import { useNhostAuth } from '@nhost/react-auth'
import { nhost } from "../utils/nhost";

import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, Image } from 'semantic-ui-react'
import { Navigate } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {GET_MY_RESPONSES} from '../utils/queries'

const ProfilePage = () => {
  const { isAuthenticated } = useNhostAuth()
  const user = nhost.auth.session.user;
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  const userID = nhost.auth.session.user.id || ""
  const myResponses = useQuery(GET_MY_RESPONSES, {variables: { id: userID }});

  return (
    !isAuthenticated ? 
    <Navigate to='/sign-in' /> :
    <>
        <Helmet>
        <title>Profile - WebMeetings.App</title>
        <meta name="description" content="WebMeetings.App" />
        </Helmet>
        <Card centered style={{ padding: '20px', border: '1px solid yellow' }}>
        <Card.Header as="h1">{user.displayName}</Card.Header>
        <Image centered alt="User Avatar" src={user.avatarUrl} size="small" />
        <h3>{user.email}</h3>
        <ul>
            <li>Role(s): {userAllowedRoles.filter((role)=>(role !== "me")).join(", ")}</li>
            <li>User Id: {userID}</li>
        </ul>
        </Card>
        <Card centered fluid style={{ padding: '20px', border: '1px solid yellow' }}>
        <Card.Header as="h1">My Votes</Card.Header>
        <ul>
            {myResponses && myResponses.data && myResponses.data.responses.map(response => {
            return <li key={response.questions_id}>{response.question.title} - {JSON.parse(response.response).title} : [{response.id}] </li>
            })}
        </ul>
        </Card>
    </>)
}

export default ProfilePage