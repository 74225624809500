import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {GET_MEETINGS} from '../utils/queries'
import { Card, Header, Message, Segment } from 'semantic-ui-react'

const MeetingsList = () => {
  const { loading, error, data } = useQuery(GET_MEETINGS)

  let returnValue = <></>
  if (data) returnValue = <>

    <Segment vertical>
      <Header as='h2'>Private Meetings</Header>
      <Message warning>No private meetings for this user</Message>
    </Segment>

    <Segment vertical>
      <Header as='h2'>Public Meetings</Header>
      <Card.Group itemsPerRow={4}>
        {data.meetings.map( (meeting) => (
            <Card raised key={meeting.id}>
              <Card.Content>
                <Card.Header><Link to={`/queues` /*`/meeting/${meeting.id}`*/}>{meeting.title}</Link></Card.Header>
                <Card.Meta>
                  <p>{new Date(meeting.start).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}<br />{new Date(meeting.start).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}</p>
                </Card.Meta>
              </Card.Content>
            </Card>
          ))}        
      </Card.Group>
    </Segment>

    <Segment vertical>
      <Header as='h2'>Past Meetings</Header>
      <Message warning>No past meetings for this user</Message>
    </Segment>

  </>
  if (!data) returnValue = <p>No data</p>
  if (loading) returnValue = <p>Loading...</p>
  if (error) returnValue = <p>Error! {error.message}</p>

  return returnValue
}

export default MeetingsList