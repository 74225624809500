import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Countdown from '../Countdown';
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { Button, Card, Checkbox, Confirm, Divider, Header, Message } from 'semantic-ui-react';
import { GET_ACTIVE_QUESTIONS_WITH_RESPONDERS, GET_MY_RESPONSES, ADD_RESPONSE } from '../../utils/queries'

import { useNhostAuth } from '@nhost/react-auth'
import { nhost } from "../../utils/nhost";

const CheckboxWrapper = styled.div`
  margin: 1rem 0;
  .ui.checkbox label {
    font-size: 2rem;
    line-height: 1;
  }
  .checkbox label:before, .checkbox label:after {
    transform: scale(1.5) translate(80%, 30%);
  }
`
const deadlineText = "10 Mar 2022 21:30:00 PST"
const deadlineDate = Date.parse(deadlineText)

function QuestionTypeRankedChoice({}) {

  const { isAuthenticated, user } = useNhostAuth()
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  const isResponder = userAllowedRoles.includes("responder")
  const myResponses = useQuery(GET_MY_RESPONSES, {variables: { id: user.id }});
  const [addResponse] = useMutation(ADD_RESPONSE, {
    context: {
      headers: {
        'x-hasura-role': 'responder'
      }
    }
  });

  const hasNoneOfTheAbove = true;
  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasResponded, setHasResponded] = useState(true)
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')
  const activeQuestionsWithResponders = useSubscription(GET_ACTIVE_QUESTIONS_WITH_RESPONDERS)

  // const responders = !activeQuestionsWithResponders.data ? [] 
  //   : activeQuestionsWithResponders.data.questions[0].responses.map(response => response.user.displayName)
    const responders = []

    // useEffect(()=>{
    //   activeQuestionsWithResponders.data && setHasResponded(activeQuestionsWithResponders.data.questions[0].responses.filter(response => response.user.id === user.id).length)
    // },[activeQuestionsWithResponders.loading])
    useEffect(()=>{
      activeQuestionsWithResponders.data && setHasResponded(false)
    },[activeQuestionsWithResponders.loading])

  const [rankedList, setRankedList] = useState([])
  const handleRankedCheckbox = (choice) => {
    if (rankedList.includes(choice)) {
      setRankedList(rankedList.filter((value) => value !== choice));
    } else {
      setRankedList([...rankedList, choice]);
    }
  }

  const handleOnClickRanked = () => {
    setServerError("")
    setServerSuccess("")
    setServerStatus("Submitting response to server...")
    setHasResponded(true)
    addResponse({ variables: {
      questions_id: "25515fa2-f8a5-4bb8-96e1-73546ddb769a",
      response: JSON.stringify(noneOfTheAbove ? ['NOTA'] : [...rankedList]),
      state: "user_submitted",
      users_id: user.id
    }})
    .then(res => {
      setServerError("")
      setServerSuccess("Your response has been stored on the server.")
      setServerStatus("")
      })
    .catch(error => {
      setServerError("Your response was rejected by the server.")
      setServerSuccess("")
      setServerStatus("")
      setHasResponded(false)
    })
    setShowConfirmModal(false);
  };

  const rankedOptions = [
      {text:"Alberto Santiago Alverez"}
      ,{text:"Matt Hartley"}
      ,{text:"Tyler Janke"}
      ,{text:"Sandra Kallander"}
      ,{text:"Priya Mulvihill"}
      ,{text:"Julian Nicholson"}
      ,{text:"Robert Parks"}
      ,{text:"Marybeth Yuskavage"}
  ]

  return (
    <Card fluid>
    <Card.Content>
      <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>LPC Delegates for 2022 National Convention in Reno</div>
      </Card.Header>
      { responders.length ? <Card.Meta>{responders.length} Response{responders.length > 1 ? `s`:null}: {responders.join(", ")}</Card.Meta> : null}
      <Card.Meta>Expires: {deadlineText}</Card.Meta>
      <Card.Description>
        <Countdown
          hidePastZeros={true}
          deadline={deadlineDate}
        />
        {!isResponder ? <Message info>You do not have the rights to respond to this question.</Message> : <>
          <Header>Choices for ranking:</Header>
          {rankedOptions.map((opt) => (
            <CheckboxWrapper
              key={opt.text}
              style={{
                display: rankedList.includes(opt.text) ? 'none' : 'block',
              }}
            >
              <Checkbox
                label={opt.text}
                disabled={noneOfTheAbove || rankedList.includes(opt.text)}
                checked={false}
                onChange={() => handleRankedCheckbox(opt.text)}
              />
            </CheckboxWrapper>
          ))}
          <Divider horizontal />
          <hr />
          <Header>Ranked order:</Header>
          <Divider horizontal />
          {rankedList.map((opt, index) => (
            <CheckboxWrapper key={opt}>
              <Checkbox
                label={`[${index + 1}]: ${opt}`}
                disabled={noneOfTheAbove}
                checked
                onChange={() => handleRankedCheckbox(opt)}
              />
            </CheckboxWrapper>
          ))}
          <CheckboxWrapper
            style={{ display: hasNoneOfTheAbove ? 'block' : 'none' }}
          >
            <Checkbox
              label="NOTA: None Of The Above"
              checked={noneOfTheAbove}
              onChange={() => setNoneOfTheAbove(!noneOfTheAbove)}
            />
          </CheckboxWrapper>
          <Divider horizontal />
          <hr />
          {hasResponded ? 
          <h2>You have already responded to this question.</h2>
          :
            <Button
              primary
              size="large"
              fluid
              type="submit"
              onClick={() => setShowConfirmModal(true)}
            >Submit Your Ballot</Button>
          }
          { !serverStatus ? null : 
            <Message info>{serverStatus}</Message>
          }
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
          { !serverSuccess ? null : 
            <Message success>{serverSuccess}</Message>
          }
          <Confirm
            open={showConfirmModal}
            content={ `Are you sure you want to submit: ${noneOfTheAbove ? ['NOTA'] : rankedList.length ? rankedList.join(", ") : "a blank ballot"}?`}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={handleOnClickRanked}
          />
        </>}
      </Card.Description>
    </Card.Content>
  </Card>
 )
}

export default QuestionTypeRankedChoice