import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

import { Container, Header } from 'semantic-ui-react'

const HelpPage = () => {
  return (<>
    <Helmet>
      <title>Help - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Header size="huge">Instructions:</Header>
      <Header block size="large">System Access (Sign Up, Sign In, Forgot Password, Sign Out)</Header>
      <ul>
        <li>If you have not registered for the application, you need to go to the <Link to="/sign-up">Sign Up</Link> page and provide your name, email address, and a password you would like to use.</li>
        <li>If you return to the system and you are not logged in, go to the <Link to="/sign-in">Sign In</Link> page and enter your email and password.</li>
        <li>If you have forgotten your password, you can use the <Link to="/forgot-password">reset</Link> process which requires you to recieve an email with a link. These sometimes end up in spam.</li>
        <li>When you are done using the system, you can <Link to="/sign-out">Sign Out</Link>.</li>
      </ul>
      <Header block size="large">Roles</Header>
      <ul>
        <li>PUBLIC: Anyone who isn't currently logged in has this role. They can see the help, sign up, sign in, and forgotten password pages.</li>
        <li>USER: Anyone who is currently logged in has this role. They can see the profile, home, agenda, delegations, help, and sign out pages.</li>
        <li>QUESTIONER: Anyone who is allowed to create questions. They can see the questions page and can create a new question.</li>
        <li>RESPONDER: Anyone who is allowed to respond to questions. They can see the questions page and can respond to a question.</li>
        <li>TELLER: Anyone who can see question responses before they are released. They can see the questions page and can see responses before they are released. They can manage write-in responses.</li>
        <li>RECOGNIZER: Anyone who is allowed to recognize people seeking recognition. They can see the queue page and can remove people from the queue.</li>
        <li>SEEKER: Anyone who is allowed to seek recognition. They can see the queue page and can add/remove themselves to/from the queue.</li>
        <li>CREDENTIALER: Anyone who is allowed to add people, remove people, and change people's persmissions. They can see the accounts page.</li>
        <li>CONFIGER: Anyone who is allowed to change the meeting settings. They can see the settings page.</li>
      </ul>
      <Header block size="large">Taking Action (Voting and Queues)</Header>
      <ul>
        <li>After logging into the system, you will primarily be using the system for voting. This is done on the <Link to="/questions">questions</Link> page.</li>
      </ul>

      <Header block size="large">Thanks</Header>
      <span>This service is provided free of charge by the Porcupine Group. If you would like to thank the creators, please contribute to the California Libertarian Party Candidate Supprt Fund <Link to="//www.candidate.fund" target="_blank" rel="noopener noreferrer">(https://candidate.fund)</Link>.</span>
    </Container>
  </>)
  }

  export default HelpPage