import { nhost } from "../utils/nhost";
import { useMutation } from "@apollo/client";

import { useNhostAuth } from "@nhost/react-auth";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Grid, Header, Image, Message, Segment, Radio } from 'semantic-ui-react'

import md5 from 'md5-es';

import { INSERT_AVATAR } from '../utils/queries'

const SignUp = () => {
//   const router = useRouter()
  const [email, setEmail] = useState('')
  const [avatarURL, setAvatarURL] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [gravatarType, setGravatarType] = useState('robohash')
  const [serverError, setserverError] = useState('')
  const [redirectHome, setRedirectHome] = useState(false)
  const { isAuthenticated } = useNhostAuth();

  const [insertAvatar] = useMutation(INSERT_AVATAR);

  useEffect(()=>{
  },[redirectHome])

  async function handleSubmit(el) {
      el.preventDefault();

      try {
        setserverError("")
        const signUpResponse = await nhost.auth.signUp({
            email: email,
            password: password,
            options: {
              displayName: displayName
            }
        });
        if(signUpResponse.error) {
          setserverError("The server was unable to register your account.")
          setRedirectHome(false)
        } else {
          setserverError("")
          const updateUserResponse = await insertAvatar({ variables: {email: email,avatarUrl:avatarURL } })
          if(updateUserResponse.data.updateUsers.returning[0]) {
            setserverError("Account created and avatar saved. Please check your email to verify account.")
            setRedirectHome(true)
            } else {
              setserverError("Could not update avatar")
              setRedirectHome(false)
          }
        }
      } catch (error) {
        setserverError("The server was unable to register your account.")
      }
  };

  const setEmailAndAvatarURL = (newEmail) => {
    setEmail(newEmail)
    setAvatarURL(newEmail?'https://www.gravatar.com/avatar/' + md5.hash(newEmail.trim().toLowerCase()) + '?d=' + gravatarType:"")
  }

  const setGravatarTypeAndAvatarURL = (newType) => {
    setGravatarType(newType)
    setAvatarURL(email?'https://www.gravatar.com/avatar/' + md5.hash(email.trim().toLowerCase()) + '?d=' + newType:"")
  }

  return (
    isAuthenticated || redirectHome ? 
    <Navigate to='/sign-in' /> :
    <>
      <Helmet>
        <title>Sign Up - WebMeetings.App</title>
        <meta name="description" content="WebMeetings.App" />
      </Helmet>
      <Grid textAlign='center' style={{ height: '60vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
              Signup for WebMeetings.App
          </Header>
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input 
                fluid 
                required
                icon='user' 
                iconPosition='left' 
                placeholder='Display Name' 
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
              <Form.Input 
                fluid 
                required
                icon='at' 
                iconPosition='left' 
                placeholder='E-mail: name@example.com' 
                value={email}
                onChange={(e) => setEmailAndAvatarURL(e.target.value)}
              />
              <Form.Input
                fluid
                required
                icon='lock'
                iconPosition='left'
                placeholder='Password: ••••••••'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button 
                color='teal' 
                fluid size='large'
                type="submit"
              >
                Signup
              </Button>
              {!displayName && !avatarURL? null : 
                <Card fluid centered style={{ padding: '20px', border: '1px solid yellow' }}>
                  <Card.Header as="h1">{displayName}</Card.Header>
                  {!avatarURL? null :<Image centered src={avatarURL} size="small" />}
                </Card>}
              <Form.Group inline>
                <label>Avatar Type</label>
                <Form.Field
                  control={Radio}
                  label='Robot'
                  value='robohash'
                  checked={gravatarType === 'robohash'}
                  onChange={(e)=>setGravatarTypeAndAvatarURL('robohash')}
                />
                <Form.Field
                  control={Radio}
                  label='Monster'
                  value='monsterid'
                  checked={gravatarType === 'monsterid'}
                  onChange={(e)=>setGravatarTypeAndAvatarURL('monsterid')}
                />
                <Form.Field
                  control={Radio}
                  label='Face'
                  value='wavatar'
                  checked={gravatarType === 'wavatar'}
                  onChange={(e)=>setGravatarTypeAndAvatarURL('wavatar')}
                />
                <Form.Field
                  control={Radio}
                  label='8-bit'
                  value='retro'
                  checked={gravatarType === 'retro'}
                  onChange={(e)=>setGravatarTypeAndAvatarURL('retro')}
                />
              </Form.Group>
            </Segment>
          </Form>
          <Message>
            Already have an account? <Link to="/sign-in">Sign In</Link>
          </Message>
          <Message>
            Forgot Password? <Link to="/forgot-password">Request password reset email</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default SignUp