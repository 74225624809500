import { Helmet } from 'react-helmet'
import { Container, Header, Tab } from 'semantic-ui-react'

import { QTPollCreate, QTPollDisplay } from '../components/QuestionTypes/QT_Poll'
import { QTBallotCreate, QTBallotDisplay } from '../components/QuestionTypes/QT_Ballot'
import QuestionTypeRankedChoice from '../components/QuestionTypes/QT_RankedChoice'

import { IsUserAllowedAccess } from '../utils/helpers'
import { useNhostAuth } from '@nhost/react-auth'

import { useSubscription } from "@apollo/client";
import { GET_ACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES
  , GET_INACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES
  , GET_RELEASED_QUESTIONS_WITH_RESPONDER_RESPONSES } from '../utils/queries'

const QuestionsPage = () => {
  const { isAuthenticated, user } = useNhostAuth()
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  const isTeller = IsUserAllowedAccess(userAllowedRoles, ["teller"], [])
  const isQuestioner = IsUserAllowedAccess(userAllowedRoles, ["questioner"], [])
  const isResponder = IsUserAllowedAccess(userAllowedRoles, ["responder"], [])

  const activeQuestionsWithResponderResponses = useSubscription(GET_ACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES)
  const inactiveQuestionsWithResponderResponses = useSubscription(GET_INACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES)
  const releasedQuestionsWithResponderResponses = useSubscription(GET_RELEASED_QUESTIONS_WITH_RESPONDER_RESPONSES)
  const panes = []

  isResponder && panes.push({
    menuItem: 'Active Questions',
    render: () => (
      <Tab.Pane>
        <Header>Questions which have not expired</Header>
        {
          activeQuestionsWithResponderResponses.loading ? null : 
          activeQuestionsWithResponderResponses.data.questions.map((question) => {
            switch (question.type) {
              case 'ranked': return (<QuestionTypeRankedChoice key={question.id} question={question} />)
              case 'ballot': return (<QTBallotDisplay key={question.id} question={question} />)
              case 'poll': return (<QTPollDisplay key={question.id} question={question} />)
              default: return null
            }
          })
        }
      </Tab.Pane>
    )
  })

  isQuestioner && panes.push({
    menuItem: 'Pending Questions',
    render: () => (
      <Tab.Pane>
        <Header>Questions Pending Release</Header>
        {
          inactiveQuestionsWithResponderResponses.loading ? null : 
          inactiveQuestionsWithResponderResponses.data.questions.map((question) => {
            switch (question.type) {
              case 'ranked': return (<QuestionTypeRankedChoice key={question.id} question={question} />)
              case 'ballot': return (<QTBallotDisplay key={question.id} question={question} />)
              case 'poll': return (<QTPollDisplay key={question.id} question={question} />)
              default: return null
            }
          })
        }
      </Tab.Pane>
    )
  })

  isResponder && panes.push({
    menuItem: 'Released Questions',
    render: () => (
      <Tab.Pane>
        <Header>Released Questions</Header>
        {
          releasedQuestionsWithResponderResponses.loading ? null : 
          releasedQuestionsWithResponderResponses.data.questions.map((question) => {
            switch (question.type) {
              case 'ranked': return (<QuestionTypeRankedChoice key={question.id} question={question} />)
              case 'ballot': return (<QTBallotDisplay key={question.id} question={question} />)
              case 'poll': return (<QTPollDisplay key={question.id} question={question} />)
              default: return null
            }
          })
        }
      </Tab.Pane>
    )
  })

  isQuestioner && panes.push({
    menuItem: 'Add A Question',
    render: () => (
      <Tab.Pane>
        <Header>Add A Question</Header>
        <QTPollCreate />
        <QTBallotCreate />
      </Tab.Pane>
    )
  })

  return (<>
    <Helmet>
      <title>Questions - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Tab menu={{ attached: true, tabular: true }} panes={panes} />
    </Container>
  </>)
  }

  export default QuestionsPage