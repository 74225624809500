import { nhost } from "./utils/nhost"
import { NhostAuthProvider } from "@nhost/react-auth"
import { NhostApolloProvider } from "@nhost/react-apollo"
import BaseLayout from './layouts/BaseLayout'
import { AppRouter } from './utils/routes'

function App() {
  return (
    <NhostAuthProvider nhost={nhost} >
      <NhostApolloProvider nhost={nhost} >
        <BaseLayout >
          <AppRouter />
        </BaseLayout>
      </NhostApolloProvider>
    </NhostAuthProvider>
  );
}

export default App;