import { useNhostAuth } from '@nhost/react-auth'
import { nhost } from "../utils/nhost";
import { Navigate } from "react-router-dom";

import React, { useState } from "react";
import { useSubscription, useMutation } from "@apollo/client";
import { Helmet } from 'react-helmet';
import { Button, Card, Container, Form, Icon, Input, Message, Table, TextArea } from 'semantic-ui-react';
import { 
        GET_USERS
         , GET_ROLES
         , ADD_EXISTING_ROLE_TO_USER
         , REMOVE_EXISTING_ROLE_FROM_USER
         , DELETE_ACCOUNT
         , ADD_ATTENDEE
       } from '../utils/queries'

const AccountsPage = () => {
  const { isAuthenticated } = useNhostAuth()
  // const user = nhost.auth.session.user;
  // const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  // const userID = nhost.auth.session.user.id || ""

   const { loading, error, data } = useSubscription(GET_USERS);
   const { loading:loadingRoles, error:errorRoles, data:dataRoles } = useSubscription(GET_ROLES);


   const [addExistingRoleToUser] = useMutation(ADD_EXISTING_ROLE_TO_USER, {
    context: {
      headers: {
        'x-hasura-role': 'credentialer'
      }
    }
  })
  const [removeExistingRoleFromUser] = useMutation(REMOVE_EXISTING_ROLE_FROM_USER, {
    context: {
      headers: {
        'x-hasura-role': 'credentialer'
      }
    }
  })

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    context: {
      headers: {
        'x-hasura-role': 'credentialer'
      }
    }
  });
  const [linkAccount] = useMutation(ADD_ATTENDEE, {
    context: {
      headers: {
        'x-hasura-role': 'credentialer'
      }
    }
  });

  const [newAccountsArray, setnewAccountsArray] = useState('[ ]')

  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [password, setPassword] = useState('')
  const [serverError, setserverError] = useState('')

  const removeExistingRole = (existingUser, existingRole) => {
    console.log("Removing:",existingRole," from ",existingUser)
    removeExistingRoleFromUser({ variables: {
      account_id: existingUser
      , role: existingRole
    }})
  }
  const addExistingRole = (existingUser, newRole) => {
    addExistingRoleToUser({ variables: {
      account_id: existingUser
      , role: newRole
    }})
  }
  const deleteAnAccount = (accountID) => {
     deleteAccount({ variables: {
      id: accountID
    }})
  }
  const linkAnAccount = (accountID, display_name) => {
    linkAccount({ variables: {
      users_id: accountID
      , display_name: display_name
   }})
 }
  // const importAccounts = async () => {
  //   Promise.all(JSON.parse(newAccountsArray).map((currentNewAccount) => {
  //     registerAccount(
  //       currentNewAccount.email
  //       , currentNewAccount.email
  //       , currentNewAccount.name
  //     )
  //     }));
  // }

  const addAccount = async () => {
    await registerAccount(email, password, displayName)
  }

  const registerAccount = async (email, password, displayName) => {
    try {
      setserverError("")
      const signUpResponse = await nhost.auth.signUp({
        email: email,
        password: password,
        options: {
          displayName: displayName
        },
      })
    } catch (error) {
      console.error(error)
      setserverError("The server was unable to register your account.")
    }
  }

  if (loading || loadingRoles) return <div>Loading</div>;
  if (error || errorRoles) console.error(error);

  return (
    !isAuthenticated ? 
    <Navigate to='/sign-in' /> :
    <>
    <Helmet>
      <title>Accounts - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Card
        key="Accounts"
        fluid
        centered
        style={{ padding: '20px', border: '1px solid yellow' }}
      >
        <Card.Header as="h1">
          Accounts
        </Card.Header>
        <Table celled compact="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.HeaderCell>Seated Groups</Table.HeaderCell>
              <Table.HeaderCell>Delete</Table.HeaderCell>
              <Table.HeaderCell>Fix</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        <Table.Body> 
          {data && dataRoles && data.users.sort((a,b)=>a.email.localeCompare(b.email)).map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell>{user.displayName}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{dataRoles.authRoles.filter(role => role.role !== "me").map(role=>(
                  user.roles.filter(userRole=>userRole.role==role.role).length == 0 ? 
                    <Button
                      key={role.role}
                      color={"grey"}
                      basic={true}
                      onClick={() => addExistingRole(user.id, role.role)}
                      style={{}}
                    >
                      {role.role}
                    </Button> 
                  :
                    <Button
                      key={role.role}
                      color={"yellow"}
                      basic={false}
                      onClick={() => removeExistingRole(user.id, role.role)}
                      style={{}}
                    >
                      {role.role}
                    </Button>
                ))}</Table.Cell>
                <Table.Cell>{ }</Table.Cell>
                <Table.Cell><Icon color="red" name="trash alternate" onClick={() => deleteAnAccount(user.id)} /></Table.Cell>
                <Table.Cell><Icon color="teal" name="configure" onClick={() => linkAnAccount(user.id, user.displayName)} /></Table.Cell>
              </Table.Row>
          ))}
          </Table.Body>
          <Table.Footer>
          </Table.Footer>
        </Table>
      </Card>
      <Card
        key="Add Account"
        fluid
        centered
        style={{ padding: '20px', border: '1px solid yellow' }}
      >
        <Card.Header as="h1">
          Add Single Account
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
        </Card.Header>
        <Form>
        <Table celled compact="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Password</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                  <Input
                    focus
                    fluid
                    onChange={({ target: { value } }) => setDisplayName(value)}
                    placeholder="Display Name"
                    style={{minWidth: "200px"}}
                  />
              </Table.Cell>
              <Table.Cell>
                  <Input
                    focus
                    fluid
                    onChange={({ target: { value } }) => setEmail(value)}
                    placeholder="Email"
                    style={{minWidth: "250px"}}
                  />
              </Table.Cell>
              <Table.Cell>
                  <Input
                    fluid
                    required
                    icon='lock'
                    iconPosition='left'
                    placeholder='Password: ••••••••'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{minWidth: "200px"}}
                  />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button
              color="orange"
              icon="upload"
              content="Add Account"
              onClick={() => addAccount()}
            />
        </Form>
      </Card>
      {/* <Card
        key="BulkUpload"
        fluid
        centered
        style={{ padding: '20px', border: '1px solid yellow' }}
      >
        <Card.Header as="h1">
          Bulk Upload Accounts
        </Card.Header>
        <Form>
            <TextArea
              value={newAccountsArray}
              rows={20}
              onChange={({ target: { value } }) => setnewAccountsArray(value)}
            />
            <Button
              color="orange"
              floated="right"
              icon="upload"
              content="Import Accounts"
              size="massive"
              onClick={() => importAccounts()}
            />
        </Form>
      </Card> */}
    </Container>
  </>)
}

export default AccountsPage