import React from "react";
import { Container, Header } from 'semantic-ui-react'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.state = { error: '' };
      this.state = { errorInfo: '' };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        this.state.error = error
        this.state.errorInfo = errorInfo
        console.error("error", error);
        console.error("errorInfo", errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <Container fluid>
                <Header size="huge">Something went wrong. Navigate to a different page a refresh to recover.</Header>
                {this.state.error}
                {this.state.errorInfo}
            </Container>
        )
      }  
      return this.props.children; 
    }
  }