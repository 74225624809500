import { Helmet } from 'react-helmet'
import { useMutation, useQuery } from "@apollo/client";
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState, useEffect } from 'react';
import { Button, Card, Container, Message } from 'semantic-ui-react'
import { UPSERT_USER_HTML, GET_DYNAMIC_HTML } from '../utils/queries'

const SystemConfigPage = () => {
  const editorRef = useRef(null)
  const [insertUserHtml] = useMutation(UPSERT_USER_HTML)
  const [serverError, setserverError] = useState('')

  const dynamicHtml = useQuery(GET_DYNAMIC_HTML)
  const userHtml = dynamicHtml.data?.dynamic_html_by_pk?.user_html ?? ''

  useEffect(()=>{
  },[])

  async function handleSubmit() {
    if (editorRef.current) {
      const userHtml = editorRef.current.getContent();
      try {
        setserverError('')
        const insertUserHtmlResponse = await insertUserHtml({
          variables: {
            html: userHtml
          }
        });
      } catch (error) {
        setserverError('Could not upload html payload')
      }
    }
  }

  return (<>
    <Helmet>
      <title>Settings - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Card
        key="Dynamic HTML"
        fluid
        centered
        style={{ padding: '20px', border: '1px solid yellow' }}
      >
        <Card.Header as="h1">
          Home Page Content
        </Card.Header>
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={userHtml}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | link',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
          <Button
              color='teal'
              fluid
              size='large'
              onClick={() => handleSubmit()}
            >
              Update Home Page Content
            </Button>
          { !serverError ? null : 
                <Message error>{serverError}</Message>
              }
      </Card>
    </Container>
    
  </>)
}
export default SystemConfigPage