import { useNhostAuth } from "@nhost/react-auth";
import React from "react";
import { Navigate, useLocation } from "react-router";

export function RequireAuth({ children }) {
  const { isAuthenticated, isLoading } = useNhostAuth();
  const location = useLocation();

  if (isLoading) {
    return <h1 style={{fontSize:"8rem", color:'red'}}>Loading user data...</h1>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  return children;
}