import React from 'react';
import { Label } from 'semantic-ui-react';

function decodeHtml(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

function GridItem({ label, link, color, style, onClickCallback, item }) {
  return (
    <Label
      as={!onClickCallback || link ? 'a' : 'span'}
      href={!onClickCallback || link ? link : null}
      onClick={
        !onClickCallback
          ? null
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickCallback(item);
            }
      }
      color={color}
      style={style}
    >
      {decodeHtml(label)}
    </Label>
  );
}

export default GridItem;