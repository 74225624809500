import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import GridItem from './GridItem';

const Wrapper = styled.div`
display: inline-grid;
grid-template-columns: repeat(${props => props.cssColumns}, ${props => props.gridCellSize});
gap: ${props => props.gridGap};
${props => props.styleString}
`;

function GridList({
  gridColumnCount,
  gridCellSize,
  gridGap,
  onClickCallback,
  list,
  renderDetails,
  renderNumbers,
  primaryAxis,
  styleString,
}) {
  const subColumns = (renderNumbers ? 1 : 0) + 1 + (renderDetails ? 1 : 0);
  const cssColumns = gridColumnCount * subColumns;
  const numberColumnPosition = renderNumbers ? 1 : 0;
  const personColumnPosition = numberColumnPosition + 1;
  const detailColumnPosition = renderDetails ? personColumnPosition + 1 : 0;

  return (
    <Wrapper cssColumns={cssColumns} gridCellSize={gridCellSize} gridGap={gridGap} styleString={styleString}>
      {list.map((item, index) => {
        const gridColumnStart =
          primaryAxis === 'column'
            ? Math.floor(index / Math.ceil(list.length / gridColumnCount)) *
              subColumns
            : (index % gridColumnCount) * subColumns;
        const gridRowStart =
          primaryAxis === 'column'
            ? (index % Math.ceil(list.length / gridColumnCount)) + 1
            : Math.floor(index / gridColumnCount) + 1;
        return (
          <Fragment key={index}>
            {renderNumbers ? (
              <Label
                circular
                style={{
                  gridColumn: `${
                    gridColumnStart + numberColumnPosition
                  } / span 1`,
                  gridRow: `${gridRowStart} / span 1`,
                }}
              >
                {index + 1}
              </Label>
            ) : null}
            <GridItem
              style={{
                gridColumn: `${
                  gridColumnStart + personColumnPosition
                } / span 1`,
                gridRow: `${gridRowStart} / span 1`,
              }}
              label={item.label}
              link={item.link}
              item={item}
              onClickCallback={onClickCallback}
              color={item.color}
            />
            {renderDetails ? (
              <Label
                as="span"
                tag
                style={{
                  gridColumn: `${
                    gridColumnStart + detailColumnPosition
                  } / span 1`,
                  gridRow: `${gridRowStart} / span 1`,
                }}
              >
                {item.detail}
              </Label>
            ) : null}
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

export default GridList;