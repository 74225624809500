 import React from 'react';
 import styled, { css } from 'styled-components';
 
 const Wrapper = styled.div`
 display: inline-grid;
 grid-template-columns: repeat(${props => props.gridColumnCount}, ${props => props.gridCellSize});
 grid-template-rows: repeat(${props => props.gridRowCount}, ${props => props.gridCellSize});
 grid-template-areas: ${props => props.tileLayout};
 gap: ${props => props.gridGap};
`;

const Tile = styled.div`
 background-color: hsla(0, 0%, 50%, 100%);
 color: ${props => props.tileTextColor};
 border-radius: ${props => props.tileRadius};
 box-sizing: border-box;
 height: 100%;
 width: 100%;
 padding: ${props => props.tilePadding};

 ${props =>
   props.position &&
   css`
     grid-area: ${props.position};
   `}
 ${props =>
   props.status &&
   props.statusType === 'percentage' &&
   css`
     background-color: hsla(${props => props.tileHue}, ${props.status}%, 50%, 100%);
   `}
 ${props =>
   props.status &&
   props.statusType == 'discrete' &&
   props.statusMap[props.status] &&
   css`
     background-color: ${props => props.statusMap[props.status].color};
   `}
`;

 function TileCartogramUs(props) {
   let statusType = 'percentage';
   let statusMap = {
     0: { name: 'Not Ready (red)', color: 'hsla(0,100%,50%,100%)' },
     100: { name: 'Ready (green)', color: 'hsla(120,100%,50%,100%)' },
   };
   let gridColumnCount = '12';
   let gridRowCount = '8';
   let gridCellSize = '36px';
   let tileHue = '10';
   let tileRadius = '10px';
   let gridGap = '2px';
   let tileTextColor = 'hsla(1,0%,25%,100%)';
   let tilePadding = '2px';
   let tileLayout = `
   "AK .. .. .. .. .. .. .. .. .. .. ME"
   ".. .. .. .. .. .. .. .. .. .. VT NH"
   "WA MT WY ND MN WI .. MI .. NY CT MA"
   "OR ID CO SD IA IL IN OH PA NJ RI .."
   "CA NV UT NE MO KY WV VA MD DE .. .."
   ".. AZ NM KS AR TN NC SC DC .. .. .."
   ".. .. .. OK LA MS AL GA .. .. .. .."
   "HI .. .. TX .. .. .. .. FL .. .. .."
   `;
   let tileData = [
     { name: 'AL', status: 0 },
     { name: 'AK', status: 0 },
     { name: 'AR', status: 0 },
     { name: 'AZ', status: 0 },
     { name: 'CA', status: 0 },
     { name: 'CO', status: 0 },
     { name: 'CT', status: 0 },
     { name: 'DE', status: 0 },
     { name: 'DC', status: 0 },
     { name: 'FL', status: 0 },
     { name: 'GA', status: 0 },
     { name: 'HI', status: 0 },
     { name: 'ID', status: 0 },
     { name: 'IL', status: 0 },
     { name: 'IN', status: 0 },
     { name: 'IA', status: 0 },
     { name: 'KS', status: 0 },
     { name: 'KY', status: 0 },
     { name: 'LA', status: 0 },
     { name: 'ME', status: 0 },
     { name: 'MD', status: 0 },
     { name: 'MA', status: 0 },
     { name: 'MI', status: 0 },
     { name: 'MN', status: 0 },
     { name: 'MS', status: 0 },
     { name: 'MO', status: 0 },
     { name: 'MT', status: 0 },
     { name: 'NE', status: 0 },
     { name: 'NV', status: 0 },
     { name: 'NH', status: 0 },
     { name: 'NJ', status: 0 },
     { name: 'NM', status: 0 },
     { name: 'NY', status: 0 },
     { name: 'NC', status: 0 },
     { name: 'ND', status: 0 },
     { name: 'OH', status: 0 },
     { name: 'OK', status: 0 },
     { name: 'OR', status: 0 },
     { name: 'PA', status: 0 },
     { name: 'RI', status: 0 },
     { name: 'SC', status: 0 },
     { name: 'SD', status: 0 },
     { name: 'TN', status: 0 },
     { name: 'TX', status: 0 },
     { name: 'UT', status: 0 },
     { name: 'VT', status: 0 },
     { name: 'VA', status: 0 },
     { name: 'WA', status: 0 },
     { name: 'WV', status: 0 },
     { name: 'WI', status: 0 },
     { name: 'WY', status: 0 },
   ];
 
   if (props.statusType) {
     statusType = props.statusType;
   }
   if (props.statusMap) {
     statusMap = props.statusMap;
   }
   if (props.gridColumnCount) {
     gridColumnCount = props.gridColumnCount;
   }
   if (props.gridRowCount) {
     gridRowCount = props.gridRowCount;
   }
   if (props.gridCellSize) {
     gridCellSize = props.gridCellSize;
   }
   if (props.gridGap) {
     gridGap = props.gridGap;
   }
   if (props.tileHue) {
     tileHue = props.tileHue;
   }
   if (props.tileRadius) {
     tileRadius = props.tileRadius;
   }
   if (props.tileTextColor) {
     tileTextColor = props.tileTextColor;
   }
   if (props.tilePadding) {
     tilePadding = props.tilePadding;
   }
   if (props.tileLayout) {
     tileLayout = props.tileLayout;
   }
   if (props.tileData) {
     tileData = props.tileData;
   }
 
   const tiles = tileData.map(tile => (
     <Tile tileTextColor={tileTextColor} tileRadius={tileRadius} tilePadding={tilePadding} statusType={statusType} tileHue={tileHue} statusMap={statusMap} status={tile.status} position={tile.name} key={tile.name}>
       {tile.name}
     </Tile>
   ));
 
   return (
     <Wrapper gridColumnCount={gridColumnCount} gridRowCount={gridRowCount} gridCellSize={gridCellSize} tileLayout={tileLayout} gridGap={gridGap} >{tiles}</Wrapper>
   );
 }
 
 export default TileCartogramUs
 