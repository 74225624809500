import { nhost } from "../utils/nhost";

import { useNhostAuth } from "@nhost/react-auth";
import {  Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import React, { useEffect } from 'react'
import { Grid, Header } from 'semantic-ui-react'

const SignOut = () => {
  nhost.auth.signOut()
  const { isAuthenticated } = useNhostAuth();

  useEffect(()=>{
  },[isAuthenticated])

  return (
    !isAuthenticated ? 
    <Navigate to='/' /> :
    <>
      <Helmet>
        <title>Sign Out - WebMeetings.App</title>
        <meta name="description" content="WebMeetings.App" />
      </Helmet>
      <Grid textAlign='center' style={{ height: '60vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
              Signing out of WebMeetings.App
          </Header>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default SignOut