import { Helmet } from 'react-helmet';

import { Container, Header } from 'semantic-ui-react'

const AgendaPage = () => {
  return (<>
    <Helmet>
      <title>Agenda - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Header size="huge">Agenda</Header>
    </Container>
  </>)
  }

  export default AgendaPage