import { Helmet } from 'react-helmet';

import { Card, Container, Header } from 'semantic-ui-react'
import Countdown from '../components/Countdown';
import TileCartogramUs from '../components/TileCartogramUS';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

const DemoPage = () => {
  var t1 = new Date()
  t1.setSeconds(t1.getSeconds() + (60*2))
  var t2 = new Date()
  t2.setSeconds(t2.getSeconds() + (60*60*2))
  var t3 = new Date()
  t3.setSeconds(t3.getSeconds() + (60*60*24*2))
  var t4 = new Date()
  t4.setSeconds(t4.getSeconds() + (60*60*24*365*2))
  var t5 = new Date()
  t5.setSeconds(t5.getSeconds() + (60*60*24*365*20))
  var t6 = new Date()
  t6.setSeconds(t6.getSeconds() + (60*60*24*365*200))
  var t7 = new Date()
  t7.setSeconds(t7.getSeconds() + (60*60*24*365*2000))
  var t8 = new Date()
  t8.setSeconds(t8.getSeconds() + (60*60*24*365*20000))

  const AllStatesData = [
    { name: 'AL', status: 0 },
    { name: 'AK', status: 10 },
    { name: 'AR', status: 20 },
    { name: 'AZ', status: 30 },
    { name: 'CA', status: 40 },
    { name: 'CO', status: 50 },
    { name: 'CT', status: 60 },
    { name: 'DE', status: 70 },
    { name: 'DC', status: 80 },
    { name: 'FL', status: 90 },
    { name: 'GA', status: 100 },
    { name: 'HI', status: 0 },
    { name: 'ID', status: 10 },
    { name: 'IL', status: 20 },
    { name: 'IN', status: 30 },
    { name: 'IA', status: 40 },
    { name: 'KS', status: 50 },
    { name: 'KY', status: 60 },
    { name: 'LA', status: 70 },
    { name: 'ME', status: 80 },
    { name: 'MD', status: 90 },
    { name: 'MA', status: 100 },
    { name: 'MI', status: 0 },
    { name: 'MN', status: 10 },
    { name: 'MS', status: 20 },
    { name: 'MO', status: 30 },
    { name: 'MT', status: 40 },
    { name: 'NE', status: 50 },
    { name: 'NV', status: 60 },
    { name: 'NH', status: 70 },
    { name: 'NJ', status: 80 },
    { name: 'NM', status: 90 },
    { name: 'NY', status: 100 },
    { name: 'NC', status: 0 },
    { name: 'ND', status: 10 },
    { name: 'OH', status: 20 },
    { name: 'OK', status: 30 },
    { name: 'OR', status: 40 },
    { name: 'PA', status: 50 },
    { name: 'RI', status: 60 },
    { name: 'SC', status: 70 },
    { name: 'SD', status: 80 },
    { name: 'TN', status: 90 },
    { name: 'TX', status: 100 },
    { name: 'UT', status: 0 },
    { name: 'VT', status: 10 },
    { name: 'VA', status: 20 },
    { name: 'WA', status: 30 },
    { name: 'WV', status: 30 },
    { name: 'WI', status: 40 },
    { name: 'WY', status: 50 },
  ]

  const proposedCurrentMotion = `
   WHEREAS, the Libertarian Party believes that the only proper role of law is in the protection of the rights of individuals from the initiation of force or fraud;
   WHEREAS, no individual has a right to prohibit consensual visitation to or consensual habitation on the private property of another individual;
   WHEREAS, the Libertarian Party believes that eminent domain is a violation of private property rights;
   WHEREAS, we affirm the right of individuals to set whatever standards they wish for entry onto their own private property but not that owned by others;
   WHEREAS, we believe that all individuals have the same rights regardless of their citizenship;
   WHEREAS, the Libertarian Party holds that any reasonable and valid concerns related to the border, valid or otherwise, are better addressed by removing the root causes of such concerns. Actions such as ending the War on Drugs, legalization of sex work, ending the welfare state, and allowing free trade would not only address those concerns, but do so in a budget positive way rather than costing billions;
   WHEREAS, the Libertarian Party acknowledges that economic freedom demands the unrestricted movement of human as well as financial capital across national borders:
   BE IT RESOLVED that the Libertarian Party condemns and opposes efforts to build a governmental border wall, be it through congress or gross abuse of authority in an ill-founded declaration of emergency.
   BE IT FURTHER RESOLVED that the Libertarian Party supports the right of freedom of movement of all peaceful people, for any reason, subject only to rights of other individuals to control their own privately owned property.
  `
  
  const proposedCurrentMotionAmendment = `
   WHEREAS, the Libertarian Party believes that the only proper role of law is in the protection of the natural rights of individuals from the initiation of force or fraud;
   WHEREAS, no individual has a natural right to prohibit consensual visitation to or consensual habitation on the private property of another individual;
   WHEREAS, the Libertarian Party believes that eminent domain is a violation of private property rights;
   WHEREAS, we affirm the right of individuals to set whatever standards they wish for entry onto their own private property but not that owned by others;
   WHEREAS, we believe that all individuals have the same natural rights regardless of their citizenship;
   WHEREAS, the Libertarian Party holds that any concerns related to the border, valid or otherwise, are better addressed by removing the root causes of such concerns. Actions such as ending the War on Drugs, decriminalization of sex work, ending the welfare state, and allowing free trade would not only address those concerns, but do so in a budget positive way rather than costing billions;
   WHEREAS, the Libertarian Party acknowledges that economic freedom demands the unrestricted movement of human as well as financial capital across national borders:
   NOW, THEREFORE, BE IT RESOLVED that the Libertarian Party condemns and opposes efforts to build a governmental border wall, be it through congress or gross abuse of authority in an ill-founded declaration of emergency.
   BE IT FURTHER RESOLVED that the Libertarian Party supports the natural right of freedom of movement of all peaceful people, for any reason, subject only to rights of other individuals to control their own privately-owned property.
  `


  return (<>
    <Helmet>
      <title>Demo - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Header size="huge">Demo Page</Header>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 minutes</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t1}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 hours</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t2}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 days</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t3}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 years</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t4}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 decades</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t5}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 centuries</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t6}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 millenniums</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t7}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Countdown Timer</Card.Header>
          <Card.Meta>2 myriads</Card.Meta>
          <Card.Description>
            <Countdown
              hidePastZeros={true}
              deadline={t8}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Tile Cartogram</Card.Header>
          <Card.Meta>United States Percentage</Card.Meta>
          <Card.Description>
            <TileCartogramUs
              statusType="percentage"
              gridColumnCount="12"
              gridRowCount="8"
              gridCellSize="28px"
              tileRadius="4px"
              tileGap="1px"
              tileTextColor="hsla(1,0%,25%,100%)"
              tilePadding="2px"
              tileLayout='
                "AK .. .. .. .. .. .. .. .. .. .. ME"
                ".. .. .. .. .. .. .. .. .. .. VT NH"
                "WA MT WY ND MN WI .. MI .. NY CT MA"
                "OR ID CO SD IA IL IN OH PA NJ RI .."
                "CA NV UT NE MO KY WV VA MD DE .. .."
                ".. AZ NM KS AR TN NC SC DC .. .. .."
                ".. .. .. OK LA MS AL GA .. .. .. .."
                "HI .. .. TX .. .. .. .. FL .. .. .."
              '
              tileHue="50"
              tileData={AllStatesData}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Tile Cartogram</Card.Header>
          <Card.Meta>United States Discrete</Card.Meta>
          <Card.Description>
            <TileCartogramUs
              statusType="discrete"
              statusMap={{
                0: {
                  name: 'Ballots Not Sent (grey)',
                  color: 'hsla(0, 0%, 50%, 100%)',
                },
                10: {
                  name: 'Ballots Not Sent (grey)',
                  color: 'hsla(0, 0%, 50%, 100%)',
                },
                20: { name: 'Ballots Sent (red)', color: 'hsla(0,100%,50%,100%)' },
                30: { name: 'Ballots Sent (red)', color: 'hsla(0,100%,50%,100%)' },
                40: {
                  name: 'Ballots Returned (orange)',
                  color: 'hsla(30,80%,50%,100%)',
                },
                50: {
                  name: 'Ballots Returned (orange)',
                  color: 'hsla(30,80%,50%,100%)',
                },
                60: {
                  name: 'Ballots Returned (orange)',
                  color: 'hsla(30,80%,50%,100%)',
                },
                70: {
                  name: 'Ballots Audited (yellow)',
                  color: 'hsla(60,100%,50%,100%)',
                },
                80: {
                  name: 'Ballots Audited (yellow)',
                  color: 'hsla(60,100%,50%,100%)',
                },
                90: {
                  name: 'Results Ready (green)',
                  color: 'hsla(120,100%,50%,100%)',
                },
                100: {
                  name: 'Results Ready (green)',
                  color: 'hsla(120,100%,50%,100%)',
                },
              }}
              gridColumnCount="12"
              gridRowCount="8"
              gridCellSize="28px"
              tileRadius="4px"
              tileGap="1px"
              tileTextColor="hsla(1,0%,25%,100%)"
              tilePadding="2px"
              tileLayout='
                "AK .. .. .. .. .. .. .. .. .. .. ME"
                ".. .. .. .. .. .. .. .. .. .. VT NH"
                "WA MT WY ND MN WI .. MI .. NY CT MA"
                "OR ID CO SD IA IL IN OH PA NJ RI .."
                "CA NV UT NE MO KY WV VA MD DE .. .."
                ".. AZ NM KS AR TN NC SC DC .. .. .."
                ".. .. .. OK LA MS AL GA .. .. .. .."
                "HI .. .. TX .. .. .. .. FL .. .. .."
              '
              tileHue="50"
              tileData={AllStatesData}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Example Admendment Comparison</Card.Header>
          <Card.Meta>"Natural" rights, "remove resonable and valid", change legalization to decriminalization, remove "NOW, THEREFORE", and add a hyphen</Card.Meta>
          <Card.Description>
          <ReactDiffViewer
            oldValue={proposedCurrentMotion}
            newValue={proposedCurrentMotionAmendment}
            splitView
            showDiffOnly={false}
            compareMethod={DiffMethod.WORDS_WITH_SPACE}
          />
          </Card.Description>
        </Card.Content>
      </Card>
    </Container>
  </>)
  }

  export default DemoPage