import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

import { Container, Header } from 'semantic-ui-react'

const DelegationsPage = () => {
  return (<>
    <Helmet>
      <title>Delegations - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Header size="huge">Delegations</Header>
    </Container>
  </>)
  }

  export default DelegationsPage