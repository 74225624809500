import { nhost } from "../utils/nhost";

import { useNhostAuth } from "@nhost/react-auth";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import React, { useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'

const ForgotPasswordPage = () => {
  const { isAuthenticated } = useNhostAuth();
  const [email, setEmail] = useState('')
  const [serverError, setserverError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')
  const [redirectHome, setRedirectHome] = useState(false)

  useEffect(()=>{
  },[redirectHome])

  async function handleSubmit(el) {
    el.preventDefault();
      try {
        setserverError("")
        setServerSuccess("")
        setServerStatus("Sending request to server to reset password ")
        const forgotPasswordResponse = await nhost.auth.resetPassword({
            email: email
        });
      
        if(forgotPasswordResponse.error) {
          setserverError("The server was unable to reset your password, please double check your email. Are you sure you are registered with the system?")
          setServerSuccess("")
          setServerStatus("")
          setRedirectHome(false)
        } else {
          setserverError("")
          setServerSuccess(`Password reset link sent to ${email}`)
          setServerStatus("")
        }
      } catch (error) {
        setserverError("Unexpected Error, please contact support")
        setServerSuccess("")
        setServerStatus("")
        setRedirectHome(false)
      }
  };

  return (
    isAuthenticated || redirectHome ? 
    <Navigate to='/' /> :
    <>
      <Helmet>
        <title>Forgot Password - WebMeetings.App</title>
        <meta name="description" content="WebMeetings.App" />
      </Helmet>
      <Grid textAlign='center' style={{ height: '60vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
              Forgot Password
          </Header>
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input 
                fluid 
                required
                icon='at' 
                iconPosition='left' 
                placeholder='E-mail: name@example.com' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button 
                color='teal' 
                fluid size='large'
                type="submit"
              >
                Reset Password
              </Button>
            </Segment>
          </Form>
          <Message>
            New to us? <Link to="/sign-up">Sign Up</Link>
          </Message>
          <Message>
            Return to Sign In page <Link to="/sign-in">Sign In</Link>
          </Message>
          { !serverStatus ? null : 
            <Message info>{serverStatus}</Message>
          }
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
          { !serverSuccess ? null : 
            <Message success>{serverSuccess}</Message>
          }
        </Grid.Column>
      </Grid>
    </>
  )
}

export default ForgotPasswordPage