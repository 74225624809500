import React, { useState } from 'react';
import { Header, Button, Modal } from 'semantic-ui-react';
import { useMutation } from "@apollo/client";
import { INSERT_SEEKER } from '../utils/queries'

const QueueButton = ({ user, list, queue_id, label, icon, color }) => {

  const [openResonsList, setOpenReasonsList] = useState(false);
  const [insertSeeker] = useMutation(INSERT_SEEKER, {
    context: {
      headers: {
        'x-hasura-role': 'seeker'
      }
    }
  })

  const joinQueue = ( {group, type, detail, color} ) => {
    setOpenReasonsList(false);
    return insertSeeker({ variables: {
      type: type
      , queues_id: queue_id
      , user_id: user.id
      , attendees_id: user.id
      , label: user.displayName
      , color: color
      , detail: detail
      , group: group} })
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpenReasonsList(false)}
      onOpen={() => setOpenReasonsList(true)}
      open={openResonsList}
      trigger={
        <Button
          color={color}
          icon={icon}
          content={label}
        />
      }
    >
      <Modal.Header>Enter {label}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Select the reason you are seeking to be recognized.</Header>
          <Button.Group vertical labeled icon size="huge">
            {list.sort((a,b)=>a.display_order-b.display_order).map(reason => <Button 
              key={reason.id}
              color={reason.color}
              icon={reason.icon}
              content={reason.detail}
              onClick={() =>
                joinQueue(
                  {group: label,
                  type: reason.type,
                  detail: reason.detail,
                  color: reason.color,
                  })
              }
              style={{marginBottom:"4px"}}
            />)}
          </Button.Group>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default QueueButton;