import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Countdown from '../Countdown';
import { useMutation } from "@apollo/client";
import { Button, Card, Checkbox, Divider, Header, Icon, Input, Label, List, Message, Select, Statistic } from 'semantic-ui-react';
import { ADD_RESPONSE, ADD_QUESTION, INACTIVATE_QUESTION, RELEASE_QUESTION } from '../../utils/queries'

import { useNhostAuth } from '@nhost/react-auth'
import { durations } from '../../utils/constants';
import { IsUserAllowedAccess } from '../../utils/helpers'

const CheckboxWrapper = styled.div`
  margin: 1rem 0;
  .ui.checkbox label {
    font-size: 2rem;
    line-height: 1;
  }
  .checkbox label:before, .checkbox label:after {
    transform: scale(1.5) translate(80%, 30%);
  }
`

export function QTBallotCreate() {
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')

  const [ballotInput, setBallotInput] = useState('')
  const [newResponse, setNewResponse] = useState('')
  const [durationSelect, setDuration] = useState(90)
  const [numberResponsesAllowed, setNumberResponsesAllowed] = useState(1)
  const [allowedResponses, setAllowedResponses] = useState([])

  const removeAllowedResponse = (responseToRemove) => {
    setAllowedResponses(allowedResponses.filter(response => response.label !== responseToRemove))
  }

  const addAllowedResponse = () => {
    setAllowedResponses([...allowedResponses, {label:newResponse}])
    setNewResponse('')
  }


  const [addQuestion] = useMutation(ADD_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const handleBallotCreate = () => {
    setServerError("")
    setServerSuccess("")
    setServerStatus("Submitting question to server...")
    addQuestion({ variables: {
      duration_in_seconds: durationSelect
      , title: ballotInput
      , type: 'ballot'
      , open_time: new Date().toISOString()
      , close_time: new Date(new Date().getTime() + (1000) * durationSelect).toISOString()
      , meetings_id: "6f86dfcc-ee0b-45df-a0a1-2fc9932faf3c"
      , allowed_responses: JSON.stringify(allowedResponses)
    }})
    .then(res => {
      setServerError("")
      setServerSuccess("Your question has been stored on the server.")
      setServerStatus("")
      setBallotInput("")
      setDuration(90)
      })
    .catch(error => {
      setServerError("Your question was rejected by the server.")
      setServerSuccess("")
      setServerStatus("")
    })
  }

  return (
    <Card fluid>
    <Card.Content extra>
      { !serverStatus ? null : 
        <Message info>{serverStatus}</Message>
      }
      { !serverError ? null : 
        <Message error>{serverError}</Message>
      }
      { !serverSuccess ? null : 
        <Message success>{serverSuccess}</Message>
      }
    </Card.Content>
    <Card.Content>
      <Card.Header><Header size="huge">Create a Question - Ballot</Header></Card.Header>
      <Card.Description>
        <Divider horizontal section>Ballot Settings</Divider>
        <Input
          focus
          fluid
          onChange={({ target: { value } }) => setBallotInput(value)}
          placeholder="Type to enter the title of the ballot..."
        />
        <Divider horizontal />
        <Select
          fluid
          placeholder="Select duration"
          options={durations}
          onChange={(_e, { value }) => setDuration(value)}
          value={durationSelect}
        />
        <Divider horizontal />
        <Input
          focus
          fluid
          onChange={({ target: { value } }) => setNumberResponsesAllowed(value)}
          label="How many responses are allowed?"
          value={numberResponsesAllowed}
        />
        <Divider horizontal />
        <Input
          focus
          fluid
          onChange={({ target: { value } }) => setNewResponse(value)}
          label="Add an allowed reposnse"
          placeholder="Type the name of an allowed response..."
          value={newResponse}
        /><Button fluid primary onClick={() => addAllowedResponse()}>Add</Button>
        <Divider horizontal />
        {allowedResponses.map(response => <Label color='yellow' key={response.label}>{response.label}<Icon name='delete' onClick={() => removeAllowedResponse(response.label)} /></Label>)}
        <Divider horizontal />
        <Button fluid primary disabled={!ballotInput} onClick={handleBallotCreate}>Release Ballot to Responders</Button>
      </Card.Description>
    </Card.Content>
  </Card>
 )
}

export function QTBallotDisplay({question}) {
  const deadlineText = question.close_time
  const deadlineDate = Date.parse(deadlineText)
  const { isAuthenticated, user } = useNhostAuth()
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  const isQuestioner = IsUserAllowedAccess(userAllowedRoles, ["questioner"], [])
  const isResponder = IsUserAllowedAccess(userAllowedRoles, ["responder"], [])

  const [addResponse] = useMutation(ADD_RESPONSE, {
    context: {
      headers: {
        'x-hasura-role': 'responder'
      }
    }
  });

  const [inactivateQuestion] = useMutation(INACTIVATE_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const [releaseQuestion] = useMutation(RELEASE_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const [hasResponded, setHasResponded] = useState(true)
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')

  const hasNoneOfTheAbove = true;
  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false)
  const [votes, setVotes] = useState([]);

  const choicesLeft = noneOfTheAbove
    ? 0
    : question.number_responses_allowed - votes.length;

  const handleCheckbox = (choice) => {
    if (votes.includes(choice)) {
      setVotes(votes.filter((value) => value !== choice));
    } else if (choicesLeft) {
      setVotes([...votes, choice]);
    }
  }

  const responders = question.responses.map(response => response.user.displayName)

  useEffect(()=>{
    setHasResponded(question.responses.filter(response => response.user.id === user.id).length)
  },[question.responses, user.id])

  const handleOnClickBallot = () => {
    setServerError("")
    setServerSuccess("")
    setServerStatus("Submitting response to server...")
    setHasResponded(true)
    addResponse({ variables: {
      questions_id: question.id,
      response: JSON.stringify(noneOfTheAbove ? ['NOTA'] : [...votes]),
      state: "user_submitted",
      users_id: user.id
    }})
    .then(res => {
      setServerError("")
      setServerSuccess("Your response has been stored on the server.")
      setServerStatus("")
      })
    .catch(error => {
      setServerError("Your response was rejected by the server.")
      setServerSuccess("")
      setServerStatus("")
      setHasResponded(false)
    })
  }

  const endBallot = () => {
    inactivateQuestion({ variables: {
      questions_id: question.id
    }})
  }

  const releaseBallot = () => {
    //add results to ballot
    releaseQuestion({ variables: {
      questions_id: question.id
    }})
  }

  const calculateResults = () => {
    const result = {}
    for (const possibleResponse of JSON.parse(question.allowed_responses)) {
      result[possibleResponse.label] = 0
    }
    result["NOTA"] = 0
    question.responses.forEach(response => {
      JSON.parse(response.response).forEach(choice => {
        result[choice] = result[choice] + 1
      })
    })
    return <List divided relaxed>
      {Object.keys(result).sort((a,b) => result[b] - result[a]).map(key => 
        <List.Item>
          <Label color="yellow">{key}<Label.Detail>{result[key]}</Label.Detail></Label>
        </List.Item>)}
      </List>
  }

  useEffect(() => {
    if (noneOfTheAbove) {
      setVotes([]);
    }
  }, [noneOfTheAbove]);

  return (
    <Card fluid>
    <Card.Content extra>
      { !serverStatus ? null : 
        <Message info>{serverStatus}</Message>
      }
      { !serverError ? null : 
        <Message error>{serverError}</Message>
      }
      { !serverSuccess ? null : 
        <Message success>{serverSuccess}</Message>
      }
    </Card.Content>
    <Card.Content>
      <Card.Header><Header size="huge">{question.title}</Header></Card.Header>
      <Card.Description>
      {!isResponder ? <Message info>You do not have the rights to respond to this question.</Message> : <>
        <Divider horizontal section>Your Response</Divider>
          {hasResponded ? 
            <Message info>You have already responded to this question.</Message>
          :
          <>
            <Card.Content extra>You have {choicesLeft} choice(s) left</Card.Content>

             {JSON.parse(question.allowed_responses).map(response => 
             <CheckboxWrapper
             key={response.label}
             style={{display: 'block'}}
           >
             <Checkbox
               label={response.label}
               disabled={noneOfTheAbove}
               checked={votes.includes(response.label)}
               onChange={() => handleCheckbox(response.label)}
             />
           </CheckboxWrapper>
             )}
          <CheckboxWrapper
            style={{ display: hasNoneOfTheAbove ? 'block' : 'none' }}
          >
            <Checkbox
              label="NOTA: None Of The Above"
              checked={noneOfTheAbove}
              onChange={() => setNoneOfTheAbove(!noneOfTheAbove)}
            />
          </CheckboxWrapper>
          <Button
                  icon='send'
                  content='Submit your response'
                  size="large"
                  color="teal"
                  type="submit"
                  onClick={() => handleOnClickBallot()}
        />
          </>
          }
        </>
        }
        {question.is_active ? null : <>
          <Divider horizontal section>Results</Divider>
          {calculateResults()}
        </>}
        {!question.is_released ? null : <>
          <Divider horizontal section>Results</Divider>
          {calculateResults()}
        </>}
        <Divider horizontal section>Responses</Divider>
        {responders.length ? <Card.Meta>{responders.length} Response{responders.length > 1 ? `s`:null}: {responders.sort().join(", ")}</Card.Meta> : null}
        <Divider horizontal section>Countdown</Divider>
        <Countdown
          hidePastZeros={true}
          deadline={deadlineDate}
        />
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      Expires: {deadlineText}
      {isQuestioner && !question.is_active && !question.is_released && <>
        <br />
        <Button
                  icon='send'
                  content='RELEASE BALLOT NOW'
                  size="large"
                  color="teal"
                  type="submit"
                  onClick={() => releaseBallot()}
        />
      </>}
      {isQuestioner && question.is_active && <>
        <br />
        <Button
                  icon='hourglass end'
                  content='END BALLOT NOW'
                  size="large"
                  color="orange"
                  type="submit"
                  onClick={() => endBallot()}
        />
      </>}
    </Card.Content>
  </Card>
 )
}