import React from 'react';
import { Helmet } from 'react-helmet';

import { Segment, Container } from 'semantic-ui-react';

import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import ErrorBoundary from '../containers/ErrorBoundary'

export default function BaseLayout({ children }) {
  return (<>
    <Helmet>
      <title>WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ErrorBoundary><NavigationBar /></ErrorBoundary>
        <Segment style={{ flex: '1' }}>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Segment>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </Container>
  </>)
}