import { nhost } from "../utils/nhost";

import { useNhostAuth } from "@nhost/react-auth";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import React, { useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'

const SignIn = () => {
  const { isAuthenticated } = useNhostAuth();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [serverError, setserverError] = useState('')
  const [redirectHome, setRedirectHome] = useState(false)

  useEffect(()=>{
  },[redirectHome])

  async function handleSubmit(el) {
      el.preventDefault();

      try {
        setserverError("")
        const signInResponse = await nhost.auth.signIn({
            email: email,
            password: password
        });
        if(signInResponse.error) {
          setserverError("The server was unable to authorize you, please double check your email and password. Are you sure you are registered with the system? You can request an email to reset your password.")
          setRedirectHome(false)
        } else {
          setserverError("")
          setRedirectHome(true)
        }
      } catch (error) {
        setserverError("The server was unable to authorize you, please double check your email and password. Are you sure you are registered with the system? You can request an email to reset your password.")
      }
  };

  return (
    isAuthenticated || redirectHome ? 
    <Navigate to='/' /> :
    <>
      <Helmet>
        <title>Sign In - WebMeetings.App</title>
        <meta name="description" content="WebMeetings.App" />
      </Helmet>
      <Grid textAlign='center' style={{ height: '60vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
              Login to WebMeetings.App
          </Header>
          { !serverError ? null : 
            <Message error>{serverError}</Message>
          }
          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input 
                fluid 
                required
                icon='at' 
                iconPosition='left' 
                placeholder='E-mail: name@example.com' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                fluid
                required
                icon='lock'
                iconPosition='left'
                placeholder='Password: ••••••••'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button 
                color='teal' 
                fluid size='large'
                type="submit"
              >
                Login
              </Button>
            </Segment>
          </Form>
          <Message>
            New to us? <Link to="/sign-up">Sign Up</Link>
          </Message>
          <Message>
            Forgot Password? <Link to="/forgot-password">Request password reset email</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default SignIn