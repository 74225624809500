import { gql } from '@apollo/client';

export const GET_MEETINGS = gql`
  query GetMeetings {
    meetings {
      id
      private
      start
      title
    }
  }
`;

export const GET_MEETING_BY_PK = gql`
query GetMeetingByPK($id: uuid! = mid) {
  meetings_by_pk(id: $id) {
    id
    private
    start
    title
  }
}
`;

export const SUB_QUESTIONS_BY_MEETING_ID = gql`
subscription SubscribeQuestionsByMeetingID($id: uuid! = mid) {
  questions_aggregate(where: {meetings_id: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}
`;


//Get active questions with responders and responses
export const GET_ACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES = gql`
  subscription GetActiveQuestionsWithResponderResponses {
    questions(where: {is_active: {_eq: true}, release_responder_responses: {_eq: true}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      responses {
        response
        state
        user {
          id
          displayName
        }
      }
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

//Get inactive questions with responders and responses
export const GET_INACTIVE_QUESTIONS_WITH_RESPONDER_RESPONSES = gql`
  subscription GetActiveQuestionsWithResponderResponses {
    questions(where: {is_active: {_eq: false}, is_released: {_eq: false}, release_responder_responses: {_eq: true}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      responses {
        response
        state
        user {
          id
          displayName
        }
      }
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

//Get released questions with responders and responses
export const GET_RELEASED_QUESTIONS_WITH_RESPONDER_RESPONSES = gql`
  subscription GetReleasedQuestionsWithResponderResponses {
    questions(where: {is_released: {_eq: true}, release_responder_responses: {_eq: true}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      responses {
        response
        state
        user {
          id
          displayName
        }
      }
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;


//Get active questions with responders and hide responses
export const GET_ACTIVE_QUESTIONS_WITH_RESPONDERS = gql`
  subscription GetActiveQuestionsWithResponders {
    questions(where: {is_active: {_eq: true}, release_responder_responses: {_eq: false}, release_responders: {_eq: true}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      responses {
        state
        user {
          id
          displayName
        }
      }
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

//Get active questions with responses and hide responders
export const GET_ACTIVE_QUESTIONS_WITH_RESPONSES = gql`
  subscription GetActiveQuestionsWithResponses {
    questions(where: {is_active: {_eq: true}, release_responder_responses: {_eq: false}, release_responses: {_eq: true}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      responses {
        response
        state
      }
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

//Get active questions with responses and hide responders
export const GET_ACTIVE_QUESTIONS_WITHOUT_RESPONDERS_OR_RESPONSES = gql`
  subscription GetActiveQuestionsWithoutRespondersOrResponses {
    questions(where: {is_active: {_eq: true}, release_responder_responses: {_eq: false}, release_responders: {_eq: false}, release_responses: {_eq: false}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

//Get inactive questions with responses and hide responders
export const GET_INACTIVE_QUESTIONS_WITHOUT_RESPONDERS_OR_RESPONSES = gql`
  subscription GetInactiveQuestionsWithoutRespondersOrResponses {
    questions(where: {is_active: {_eq: false}}) {
      id
      title
      close_time
      type
      duration_in_seconds
      release_responses
      release_responders
      release_responder_responses
      is_active
      is_released
      allowed_responses
      number_responses_allowed
    }
  }
`;

export const ADD_QUESTION = gql `
  mutation AddQuestion($duration_in_seconds: bigint = 90, $title: String = "", $type: String = "", $meetings_id: uuid = "", $open_time: timestamptz = "", $close_time: timestamptz = "", $allowed_responses: String = "") {
    insert_questions_one(object: {
      , duration_in_seconds: $duration_in_seconds
      , title: $title
      , type: $type
      , meetings_id: $meetings_id
      , open_time: $open_time
      , close_time: $close_time
      , allowed_responses: $allowed_responses
    }) {
      id
    }
  }
`

export const ADD_RESPONSE = gql `
  mutation addResponse($questions_id: uuid = "", $response: String = "", $state: String = "", $users_id: uuid = "") {
    insert_responses_one(object: {questions_id: $questions_id, user_id: $users_id, state: $state, response: $response}) {
      id
    }
  }
`

export const ADD_ATTENDEE = gql `
  mutation addAttendee($users_id: uuid = "", $display_name: String = "") {
    insert_attendees_one(object: {id: $users_id, display_name: $display_name}) {
      id
    }
  }
`

export const GET_RESPONDERS = gql `
  query getResponders {
    users(where: {account: {account_roles: {role: {_eq: "responder"}}}}) {
      id
    }
  }
`

export const INACTIVATE_QUESTION = gql `
  mutation inactivate($questions_id: uuid = "") {
    update_questions_by_pk(pk_columns: {id: $questions_id}, _set: {is_active: false}) {
      id
    }
  }
`

export const RELEASE_QUESTION = gql `
  mutation inactivate($questions_id: uuid = "") {
    update_questions_by_pk(pk_columns: {id: $questions_id}, _set: {is_released: true}) {
      id
    }
  }
`

export const GET_USERS = gql`
  subscription UsersAndRoles{
    users {
      id
      displayName
      email
      defaultRole
      roles {
        role
      }
    }
  }
`;

export const GET_ROLES = gql`
  subscription GetAuthRoles {
    authRoles {
      role
    }
  }
`;

export const GET_DYNAMIC_HTML = gql `
  query getDynamicHtml ($pk: uuid = "b403e9fe-9fad-40d1-bdf2-c58273ba41d0") {
    dynamic_html_by_pk(id: $pk) {
      user_html
    }
  }
`

export const ADD_EXISTING_ROLE_TO_USER = gql `
  mutation AddExistingRoleToUser($account_id: uuid = "", $role: String = "") {
    insertAuthUserRoles(objects: {role: $role, userId: $account_id}) {
      returning {
        id
      }
    }
  }
`

export const REMOVE_EXISTING_ROLE_FROM_USER = gql `
  mutation RemoveExistingRoleFromUser($role: String = "", $account_id: uuid = "") {
    deleteAuthUserRoles(where: {role: {_eq: $role}, userId: {_eq: $account_id}}) {
      returning {
        id
      }
    }
  }
`
export const CHANGE_DEFAULT_ROLE = gql `
  mutation ChangeDefaultRole($id: uuid! = "", $defaultRole: String = "") {
    updateUser(pk_columns: {id: $id}, _set: {defaultRole: $defaultRole}) {
      id
    }
  }
`

export const DELETE_ACCOUNT = gql `
  mutation deleteAccount($id: uuid! = "") {
    deleteUser(id: $id) {
      id
    }
  }
`

export const GET_MY_RESPONSES = gql `
  query getMyResponses($id: uuid! = "") {
    responses(where: {user_id: {_eq: $id}}) {
      id
      response
      question {
        title
      }
      questions_id
    }
  }
`

export const GET_QUEUES_WITH_SEEKERS = gql`
subscription GetQueuesWithSeekers {
  queues {
    id
    display_order
    display_name
    button_icon
    button_color
    queue_reasons {
      id
      type
      detail
      icon
      color
      display_order
    }
    seekers {
      id
      color
      label
      group
      detail
      created_at
      type
      user {
        id
        displayName
      }
    }
  }
}
`;

export const DELETE_SEEKERS = gql`
mutation DeleteSeekers($_in: [uuid!] = "") {
  delete_seekers(where: {id: {_in: $_in}}) {
  returning {
    id
  }
  }
}
`;

export const INSERT_SEEKER = gql`
mutation insertSeeker($color: String = "", $detail: String = "", $label: String = "", $group: String = "", $attendees_id: uuid = "", $user_id: uuid = "", $type: String = "", $queues_id: uuid = "") {
  insert_seekers_one(object: {type: $type, queues_id: $queues_id, attendees_id: $attendees_id, user_id: $user_id, detail: $detail, group: $group, label: $label, color: $color}) {
    id
  }
}
`;

export const INSERT_AVATAR = gql`
mutation INSERT_AVATAR($email: citext = "", $avatarUrl: String = "") {
  updateUsers(where: {email: {_eq: $email}}, _set: {avatarUrl: $avatarUrl}) {
    returning {
      avatarUrl
      displayName
      email
      emailVerified
      id
    }
  }
}
`;

export const UPSERT_USER_HTML = gql`
mutation upsert_article($html: String = "") {
  insert_dynamic_html(
    objects: [{
    	user_html: $html
    }],
    on_conflict: {
      constraint: dynamic_html_pkey,
      update_columns: [user_html]
    }
  )
  {
    returning {
      user_html
    }
  }
}
`;