export function IsUserAllowedAccess(userRolesList = [], accessRolesList = [], excludeRolesList = []) {
    return excludeRolesList.filter(permission => userRolesList.includes(permission)).length === 0 &&
    accessRolesList.filter(permission => userRolesList.includes(permission)).length > 0
}

export function GroupBy(xs, key) {
  return xs.reduce((rv, x) => {
    const newRV = rv;
    (newRV[x[key]] = newRV[x[key]] || []).push(x);
    return newRV;
  }, {});
}
