import { Helmet } from 'react-helmet'
import { useQuery } from "@apollo/client"
import { Container, Header } from 'semantic-ui-react'
import { GET_DYNAMIC_HTML } from '../utils/queries'

const HomePage = () => {
  const { loading, error, data } = useQuery(GET_DYNAMIC_HTML)
  const userHtml = (data?.dynamic_html_by_pk?.user_html ?? '')

  return (<>
    <Helmet>
      <title>Home - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      {
        userHtml === ''?
        <Header size="huge">Home Page</Header> :
        <div className="content" dangerouslySetInnerHTML={{__html: userHtml}}></div>
      }
    </Container>
  </>)
  }

  export default HomePage