import { Helmet } from 'react-helmet'

import { useQuery, useLazyQuery } from "@apollo/client"

import { Container, Header } from 'semantic-ui-react'

import { GET_DYNAMIC_HTML } from '../utils/queries'
import { useEffect } from 'react'
import MeetingsList from '../components/MeetingsList'


const MeetingsPage = () => {

  return (<>
    <Helmet>
      <title>Meetings - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
    <Container fluid>
      <Header size="huge">Meetings</Header>
      <MeetingsList />
    </Container>
  </>)
  }

  export default MeetingsPage