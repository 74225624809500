export const pollColors = {
    AYE: 'green',
    NAY: 'red',
    ABSTAIN: 'grey',
    IMPLICIT: 'teal',
  };
  
  export const durations = [45, 90, 120, 180, 300, 3600, 5400, 7200].map(
    (value) => ({
      value,
      key: value,
      text: `${value} Seconds [${value / 60} Minutes]`,
    }),
  );
  