import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownTimer = styled.div`
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 12px;
  display: block;
`;

const CountdownInterval = styled.div`
  margin-right: 3px;
  padding: 4px;
  border-radius: 3px;
  background: hsl(220, 25%, 50%);
  display: inline-block;
`;

const CountdownLabel = styled.div`
  padding-top: 3px;
  color: #fff;
`;

const CountdownDigit = styled.span`
  padding: 12px 5px;
  border-radius: 3px;
  background: hsl(220, 40%, 25%);
  display: inline-block;
  font-size: 36px;
`;

const millisecondsInASecond = 1e3    //      1,000
const millisecondsInAMinute = 60e3   //      6,000
const millisecondsInAHour   = 36e5   //  3,600,000
const millisecondsInADay    = 864e5  // 86,400,000

function Countdown({
  deadline,
  hidePastZeros,
  hideDays,
  hideHours,
  hideMinutes,
  hideSeconds,
}) {
  const [seconds, setSeconds] = useState(0);

  const millisecondsRemaining = deadline - Date.parse(new Date());
  const areSecondsRemaining = (millisecondsRemaining > 0)
  const areMinutesRemaining = (millisecondsRemaining > millisecondsInAMinute - 1)
  const areHoursRemaining = (millisecondsRemaining > millisecondsInAHour - 1)
  const areDaysRemaining = (millisecondsRemaining > millisecondsInADay - 1)

  const digitDD = {
    labelPlural: 'Days',
    labelSingular: 'Day',
    display: !hideDays && (!hidePastZeros || areDaysRemaining),
    remaining: `${Number(!areSecondsRemaining ? 0 : Math.floor(millisecondsRemaining / millisecondsInADay)).toLocaleString().padStart(2, '0')}`,
  };
  const digitHH = {
    labelPlural: 'Hours',
    labelSingular: 'Hour',
    display: !hideHours && (!hidePastZeros || areHoursRemaining),
    remaining: `${Number(!areSecondsRemaining ? 0 : Math.floor(millisecondsRemaining / millisecondsInAHour) % 24).toLocaleString().padStart(2, '0')}`,
  };
  const digitMM = {
    labelPlural: 'Minutes',
    labelSingular: 'Minute',
    display: !hideMinutes && (!hidePastZeros || areMinutesRemaining),
    remaining: `${Number(!areSecondsRemaining ? 0 : Math.floor(millisecondsRemaining / millisecondsInAMinute) % 60).toLocaleString().padStart(2, '0')}`,
  };
  const digitSS = {
    labelPlural: 'Seconds',
    labelSingular: 'Second',
    display: !hideSeconds && true,
    remaining: `${Number(!areSecondsRemaining ? 0 : Math.floor(millisecondsRemaining / millisecondsInASecond) % 60).toLocaleString().padStart(2, '0')}`,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, millisecondsInASecond);
    return () => clearInterval(interval);
  }, []);

   const cdtIntervals = [digitDD, digitHH, digitMM, digitSS].map(
    currentInterval =>
      currentInterval.display ? (
        <CountdownInterval key={currentInterval.labelSingular}>
          <CountdownDigit>{currentInterval.remaining}</CountdownDigit>
          <CountdownLabel>{currentInterval.remaining === "01" ? currentInterval.labelSingular : currentInterval.labelPlural}</CountdownLabel>
        </CountdownInterval>
      ) : null,
  );

  return <CountdownTimer>{cdtIntervals}</CountdownTimer>
}

export default Countdown