import React, { useState, useEffect } from 'react';
import Countdown from '../Countdown';
import { useMutation } from "@apollo/client";
import { Button, Card, Divider, Header, Input, Label, Message, Select, Statistic } from 'semantic-ui-react';
import { ADD_RESPONSE, ADD_QUESTION, INACTIVATE_QUESTION, RELEASE_QUESTION } from '../../utils/queries'

import { useNhostAuth } from '@nhost/react-auth'
import { pollColors, durations } from '../../utils/constants';
import { IsUserAllowedAccess } from '../../utils/helpers'

const pollOptions = ["AYE", "NAY", "ABSTAIN"];

export function QTPollCreate() {
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')

  const [pollInput, setPollInput] = useState('');
  const [durationSelect, setDuration] = useState(90);

  const [addQuestion] = useMutation(ADD_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const handlePollCreate = () => {
    setServerError("")
    setServerSuccess("")
    setServerStatus("Submitting question to server...")
    addQuestion({ variables: {
      duration_in_seconds: durationSelect
      , title: pollInput
      , type: 'poll'
      , open_time: new Date().toISOString()
      , close_time: new Date(new Date().getTime() + (1000) * durationSelect).toISOString()
      , meetings_id: "6f86dfcc-ee0b-45df-a0a1-2fc9932faf3c"
    }})
    .then(res => {
      setServerError("")
      setServerSuccess("Your question has been stored on the server.")
      setServerStatus("")
      setPollInput("")
      setDuration(90)
      })
    .catch(error => {
      setServerError("Your question was rejected by the server.")
      setServerSuccess("")
      setServerStatus("")
    })
  }

  return (
    <Card fluid>
    <Card.Content extra>
      { !serverStatus ? null : 
        <Message info>{serverStatus}</Message>
      }
      { !serverError ? null : 
        <Message error>{serverError}</Message>
      }
      { !serverSuccess ? null : 
        <Message success>{serverSuccess}</Message>
      }
    </Card.Content>
    <Card.Content>
      <Card.Header><Header size="huge">Create a Question - Poll</Header></Card.Header>
      <Card.Description>
        <Divider horizontal section>Poll Settings</Divider>
        <Input
          focus
          fluid
          onChange={({ target: { value } }) => setPollInput(value)}
          placeholder="Type to enter a yes/no question..."
        />
        <Divider horizontal />
        <Select
          fluid
          placeholder="Select duration"
          options={durations}
          onChange={(_e, { value }) => setDuration(value)}
          value={durationSelect}
        />
        <Divider horizontal />
        <Button fluid primary disabled={!pollInput} onClick={handlePollCreate}>Release Poll to Responders</Button>
      </Card.Description>
    </Card.Content>
  </Card>
 )
}

export function QTPollDisplay({question}) {
  const deadlineText = question.close_time
  const deadlineDate = Date.parse(deadlineText)
  const { isAuthenticated, user } = useNhostAuth()
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated?user.roles : []))
  const isQuestioner = IsUserAllowedAccess(userAllowedRoles, ["questioner"], [])
  const isResponder = IsUserAllowedAccess(userAllowedRoles, ["responder"], [])

  const [addResponse] = useMutation(ADD_RESPONSE, {
    context: {
      headers: {
        'x-hasura-role': 'responder'
      }
    }
  });

  const [inactivateQuestion] = useMutation(INACTIVATE_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const [releaseQuestion] = useMutation(RELEASE_QUESTION, {
    context: {
      headers: {
        'x-hasura-role': 'questioner'
      }
    }
  });

  const [hasResponded, setHasResponded] = useState(true)
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')

  // const responders = question.responses.map(response => response.user.displayName)
  const responderResponses = question.responses.map(response => {return {label: response.user.displayName, color:pollColors[JSON.parse(response.response)]}} )

  // const getPercent = (pollOption) => {
  //   const percentNumerator = question.responses.filter(a=>(JSON.parse(a.response)===pollOption)).length
  //   const percentDemoninator = question.responses.filter(a=>(JSON.parse(a.response)==="AYE" || JSON.parse(a.response)==="NAY")).length
  //   return percentDemoninator === 0 ? 0 : Math.round((percentNumerator / percentDemoninator) * 100);
  // }

    useEffect(()=>{
      setHasResponded(question.responses.filter(response => response.user.id === user.id).length)
    },[question.responses, user.id])

  const handleOnClickPoll = (response) => {
    setServerError("")
    setServerSuccess("")
    setServerStatus("Submitting response to server...")
    setHasResponded(true)
    addResponse({ variables: {
      questions_id: question.id,
      response: JSON.stringify(response),
      state: "user_submitted",
      users_id: user.id
    }})
    .then(res => {
      setServerError("")
      setServerSuccess("Your response has been stored on the server.")
      setServerStatus("")
      })
    .catch(error => {
      setServerError("Your response was rejected by the server.")
      setServerSuccess("")
      setServerStatus("")
      setHasResponded(false)
    })
  }

  const endPoll = () => {
    inactivateQuestion({ variables: {
      questions_id: question.id
    }})
  }

  const releasePoll = () => {
    releaseQuestion({ variables: {
      questions_id: question.id
    }})
  }


  return (
    <Card fluid>
    <Card.Content extra>
      { !serverStatus ? null : 
        <Message info>{serverStatus}</Message>
      }
      { !serverError ? null : 
        <Message error>{serverError}</Message>
      }
      { !serverSuccess ? null : 
        <Message success>{serverSuccess}</Message>
      }
    </Card.Content>
    <Card.Content>
      <Card.Header><Header size="huge">{question.title}</Header></Card.Header>
      <Card.Description>
      {!isResponder ? <Message info>You do not have the rights to respond to this question.</Message> : <>
        <Divider horizontal section>Your Response</Divider>
          {hasResponded ? 
            <Message info>You have already responded to this question.</Message>
          :
           <Button.Group fluid>
              <Button
                icon='thumbs up'
                content='AYE'
                size="large"
                color="green"
                type="submit"
                onClick={() => handleOnClickPoll("AYE")}
              />
              <Button.Or />
              <Button
                icon='thumbs down'
                content='NAY'
                size="large"
                color="red"
                type="submit"
                onClick={() => handleOnClickPoll("NAY")}
              />
              <Button.Or />
              <Button
                icon='dont'
                content='ABSTAIN'
                size="large"
                color="grey"
                type="submit"
                onClick={() => handleOnClickPoll("ABSTAIN")}
              />
            </Button.Group>
          }
        </>}
      <Divider horizontal section>Results</Divider>
        <Statistic.Group size="large" widths='four'>
          {pollOptions.map(pollOption => {
            return (
              <Statistic key={pollOption} color={pollColors[pollOption]}>
                <Statistic.Value>{question.responses.filter(a=>JSON.parse(a.response)===pollOption).length}</Statistic.Value>
                <Statistic.Label>{pollOption}</Statistic.Label>
                {/* <Statistic.Label>{pollOption} {getPercent(pollOption)}%</Statistic.Label> */}
              </Statistic>
            )
          })}
          <Statistic>
            <Statistic.Value>{question.responses.length}</Statistic.Value>
            <Statistic.Label>TOTAL</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <Divider horizontal section>Responses</Divider>
        {/* { responders.length ? <Card.Meta>{responders.length} Response{responders.length > 1 ? `s`:null}: {responders.sort().join(", ")}</Card.Meta> : null} */}
        { responderResponses.length ? <Card.Meta>{responderResponses.length} Response{responderResponses.length > 1 ? `s`:null}: {responderResponses.sort().map(responder => <Label color={responder.color}>{responder.label}</Label>)}</Card.Meta> : null}
        <Divider horizontal section>Countdown</Divider>
        <Countdown
          hidePastZeros={true}
          deadline={deadlineDate}
        />
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      Expires: {deadlineText}
      {isQuestioner && !question.is_active && !question.is_released && <>
        <br />
        <Button
                  icon='send'
                  content='RELEASE POLL NOW'
                  size="large"
                  color="teal"
                  type="submit"
                  onClick={() => releasePoll()}
        />
      </>}
      {isQuestioner && question.is_active && <>
        <br />
        <Button
                  icon='hourglass end'
                  content='END POLL NOW'
                  size="large"
                  color="orange"
                  type="submit"
                  onClick={() => endPoll()}
        />
      </>}
    </Card.Content>
  </Card>
 )
}