import { useNhostAuth } from "@nhost/react-auth";

import React from 'react';
import { Link } from "react-router-dom";

import { Dropdown, Icon, Menu } from 'semantic-ui-react';

import { IsUserAllowedAccess } from '../utils/helpers'

function CreateDropDownItem (item) {
  return (
    <Dropdown.Item key={item.title} onClick={(e)=>e.target.classList.contains("item") && e.target.firstChild.click()}>
      <Link to={item.href} style={item.style}><Icon name={item.icon} />{item.title}</Link>
    </Dropdown.Item>
  )
}

function CreateDropDown (dropdown, userAllowedRoles) {
  return(
    <Dropdown key={dropdown.title} item icon={dropdown.icon} text={dropdown.title} style={dropdown.style}>
      <Dropdown.Menu>
        {
          dropdown.items
            .filter(item => IsUserAllowedAccess(userAllowedRoles, item.permissions, item.excludePermissions))
            .map(item => CreateDropDownItem(item))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

function MenuMaker ({inputMenu}) {
  const { isAuthenticated, isLoading, user } = useNhostAuth();
  const userAllowedRoles = ["public"].concat(Array.from(isAuthenticated ? user.roles : []))
  return (
    isLoading ? null : 
      inputMenu
        .filter(dropdown => IsUserAllowedAccess(userAllowedRoles, dropdown.permissions, dropdown.excludePermissions))
        .map(dropdown => CreateDropDown(dropdown, userAllowedRoles))
  )
}

function NavigationBar() {
  //TODO: Read these from database so they can be configured differently for each meeting
  const leftMenu = [
    {title: "Take Action", icon: "talk", excludePermissions:[], permissions: ["user", "seeker", "recognizer", "responder", "teller", "questioner"], style:{ color: "hsl(52 99% 45% / 1)" }, items: [
      {title: "Queues", icon: "microphone", excludePermissions:[], permissions: ["user","seeker", "recognizer"], href: "/queues", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Vote", icon: "hand spock", excludePermissions:[], permissions: ["user", "responder", "teller", "questioner"], href: "/questions", style:{ color: "hsl(0 0% 0% / 1)" }}
//      {title: "Vote", icon: "hand spock", excludePermissions:[], permissions: ["demoer"], href: "/questions", style:{ color: "hsl(0 0% 0% / 1)" }}
    ]},
    {title: "Admin", icon: "podcast", excludePermissions:[], permissions: ["credentialer", "configer"], style:{ color: "hsl(0 90% 65% / 1)" }, items: [
      {title: "Accounts", icon: "universal access", excludePermissions:[], permissions: ["credentialer"], href: "/admin/accounts", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "System Config", icon: "settings", excludePermissions:[], permissions: ["configer"], href: "/admin/system-config", style:{ color: "hsl(0 0% 0% / 1)" }}
    ]}
  ]

  const rightMenu = [
    {title: "Information", icon: "info", excludePermissions:[], permissions: ["public"], style:{ }, items: [
      {title: "Agenda", icon: "file alternate", excludePermissions:[], permissions: ["demoer"], href: "/agenda", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Delegations", icon: "users", excludePermissions:[], permissions: ["demoer"], href: "/attendees", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Home", icon: "home", excludePermissions:[], permissions: ["user"], href: "/", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Help", icon: "help", excludePermissions:[], permissions: ["public"], href: "/help", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Demo", icon: "clipboard outline", excludePermissions:[], permissions: ["demoer"], href: "/demo", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Privacy", icon: "privacy", excludePermissions:[], permissions: ["user"], href: "/privacy", style:{ color: "hsl(0 0% 0% / 1)" }}
    ]},
    {title: "Account", icon: "user", excludePermissions:[], permissions: ["public"], style:{ }, items: [
      {title: "Change Meeting", icon: "grid layout", excludePermissions:[], permissions: ["user"], href: "/meetings", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Profile", icon: "user circle", excludePermissions:[], permissions: ["user"], href: "/profile", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Sign Out", icon: "sign out", excludePermissions:[], permissions: ["user"], href: "/sign-out", style:{ color: "hsl(0 0% 0% / 1)" }},
      {title: "Sign In", icon: "sign in", excludePermissions:['user'], permissions: ["public"], href: "/sign-in", style:{ color: "hsl(0 0% 0% / 1)" }}
    ]}
  ]

  return (
    <Menu
      inverted
      icon="labeled"
      style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
    >
      <MenuMaker inputMenu={leftMenu} />
      <Menu.Menu position="right">
        <MenuMaker inputMenu={rightMenu} />
      </Menu.Menu>
    </Menu>    
  )
}

export default NavigationBar;