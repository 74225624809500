import { Route, Routes } from "react-router"
import { Outlet } from "react-router-dom"
import { RequireAuth } from "../middleware/RequireAuth"

import SignUp             from '../containers/SignUp'
import SignIn             from '../containers/SignIn'
import SignOut            from "../containers/SignOut"
import HomePage           from "../containers/HomePage"
import HelpPage           from "../containers/HelpPage"
import DemoPage           from "../containers/DemoPage"
import QueuesPage         from "../containers/QueuesPage"
import AgendaPage         from "../containers/AgendaPage"
import ProfilePage        from "../containers/ProfilePage"
import PrivacyPage        from "../containers/PrivacyPage"
import AccountsPage       from "../containers/AccountsPage"
import MeetingsPage       from "../containers/MeetingsPage"
import QuestionsPage      from "../containers/QuestionsPage"
import DelegationsPage    from "../containers/DelegationsPage"
import SystemConfigPage   from "../containers/SystemConfigPage"
import ForgotPasswordPage from "../containers/ForgotPasswordPage"

export function AppRouter() {
  return (
    <Routes>
      <Route path="*" element={<div>Bad URL</div>} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/" element={<RequireAuth><Outlet /></RequireAuth>}>
        <Route path="/" element={<HomePage />} />
        <Route path="/meetings" element={<MeetingsPage />} />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/agenda" element={<AgendaPage />} />
        <Route path="/queues" element={<QueuesPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/attendees" element={<DelegationsPage />} />
        <Route path="/admin">
          <Route path="accounts" element={<AccountsPage />} />
          <Route path="system-config" element={<SystemConfigPage />} />
        </Route>
      </Route>
    </Routes>
  )
}